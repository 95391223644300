interface SpotlightSaleBannerProps {
  variant: 'PRODUCT' | 'SERPS' | 'SERPS_GENERIC';
  saving?: number;
}

const SpotlightSaleBanner = ({ saving, variant }: SpotlightSaleBannerProps) => {
  const BannerCopy = ({ saving, variant }: SpotlightSaleBannerProps) => {
    switch (variant) {
      case 'PRODUCT':
        return (
          <>
            <span className="ch-color--ac-yellow">
              Save{saving ? ` £${saving}` : ''}
            </span>{' '}
            on this car.
          </>
        );

      case 'SERPS':
        return (
          <>
            Savings you don&apos;t{' '}
            <span className="ch-display--block sl:ch-display--inline-block">
              want to miss!
            </span>
          </>
        );

      case 'SERPS_GENERIC':
      default:
        return (
          <>
            <span className="ch-mr--1">
              Savings you don&apos;t want to miss!{' '}
              <a
                className="ac-promo__link ch-color--white ch-fw--400 ch-btn ch-btn--link ch-ph--0 ch-fs--3 sm:ch-fs--4 md:ch-fs--5"
                href="/vehicles?keywords=Spotlight+cars"
              >
                Shop now
              </a>
            </span>
          </>
        );
    }
  };

  return (
    <>
      <div className="ac-promo spotlight-promo ch-justify-content--between ch-align-items--start">
        <div className="ch-color--white ch-fw--500 ch-fs--3 sm:ch-fs--4 md:ch-fs--5">
          <BannerCopy saving={saving} variant={variant} />
        </div>
        <img
          alt="Spotlight car"
          className={`ac-promo__image ac-promo__image--${
            saving ? 'product' : 'serps'
          } ch-ml--2`}
          height="30"
          width="97"
        />
      </div>
    </>
  );
};

export default SpotlightSaleBanner;
